.flex-container-nft > div {
    width: 300px;
    margin: 10px;
    text-align: center;
    /* line-height: 75px; */
    font-size: 30px;
  }
  .flex-container-nft {
    display: flex;
    flex-flow: row wrap;
  }
  .nft-img {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .metamallProject{
    /* width:480px;
    height:300px; */
    max-height:calc(100% - 40px);
    max-width: 100%;
  }
  .flex-container-metamallProject{
    display: flex;
    flex-flow: row wrap;
  }
  .flex-container-metamallProject > div {
    width: 390px;
    /* height: 510px; */
    margin: 10px;
    /* text-align: center; */
    /* line-height: 75px; */
    font-size: 20px;
  }
  .metamallProjectCard{
    width: 380px;    
    height:250px;
    width: 360px;
    margin: 30px;
    text-align: center;
    /* line-height: 75px; */
    font-size: 20px;
  }
  .metamallProjectCard {
    margin: 30px;
    padding: 0.7rem;
    text-align: center;
    color: #fcfcfc;
    background-color: #3b3b3b83;
    text-decoration: none;
    border: .1px solid #c5ced8;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 370;
  }
  .card:hover,
.card:focus,
.card:active {
  cursor: pointer;
  background-color: #1c1b1b;
}
.metamallProjectCardDescription{
  text-align: left;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 20%;
}
.metamallProjectCardDescription div{
  display: flex;

  text-align: left;

}