text{
    fill:white !important
}
path{
    stroke:white !important
}
circle{
    stroke:white !important;
    fill:gray;
}
.node__root > circle {
    fill: rgb(20, 197, 123);

  }
  
  .node__branch > circle {
    fill: yellow;
  }
  
  .node__leaf > circle {
    fill: green;
    /* Let's also make the radius of leaf nodes larger */
    /* r: 40; */
  }
  .nodeCircle{
    fill: green;
  }