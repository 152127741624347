.custom-textarea {
    --background: #3b3b3b83;
    --color: rgb(255, 255, 255);
    --padding-end: 10px;
    --padding-start: 10px;
    --placeholder-color: rgb(183, 182, 182);
    --placeholder-opacity: 0.8;
  }
  ion-input {
    width:700px;
  }

  .flex-container-nft > div {
    width: 360px;
    margin: 30px;
    text-align: center;
    /* line-height: 75px; */
    font-size: 20px;
    
  }
  .flex-container-nft {
    display: flex;
    flex-flow: row wrap;
    background: rgb(15, 8, 8,0);
    /* width: 350px; */
    color:black
  }
  .nft-img {
    width: 300px;
    height: 290px;
    object-fit: cover;
  }
  .card {
    margin: 0.5rem;
    padding: 0.7rem;
    text-align: center;
    color: #fcfcfc;
    background-color: #3b3b3b83;
    text-decoration: none;
    border: .1px solid #c5ced8;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 100%;
  }
  .card:hover,
.card:focus,
.card:active {
  cursor: pointer;
  background-color: #1c1b1b;
}

.calendar {
  margin: 1rem;
   align-items:center;
}
.item{
  --padding-start: 0px;
}

.list-md {
  padding-top: 0px;
  padding-bottom: 0px;
}