ion-card-header.ios {
    display: flex;
    flex-flow: column-reverse;
  }
  .flex {
    display: flex;
    flex-flow: row wrap;
  }
  ion-card {
    width:360px;
  }
  svg {
    width: 100%;
  }

  text {
    font-size: 14px;
  }